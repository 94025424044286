<template>
  <!-- <div class="index">
    <div class="content">
      <div style=" padding: 10px 12px;">
        <van-field
            v-model="message"
            rows="2"
            :autosize="{maxHeight:300,minHeight:150}"
            type="textarea"
            maxlength="2000"
            placeholder="请输入内容"
            show-word-limit
            style="background: #fff ;border: none;padding-bottom: 45px;"
        >
          <template #button>
            <div class="button">
              从素材中心选取
            </div>
          </template>
        </van-field>
        <van-uploader style="width: 100%;">
          <div style="    display: flex;
    align-items: center;
    width: 100%;
    background: rgb(255, 255, 255);
    padding: 10px 12px;
    margin-top: -11px;">
            <van-icon name="plus" style="color: #939393" />
            <div style="color: #444;margin-left: 10px;">上传图片/视频</div>
          </div>
        </van-uploader>
        <span style="color: #939393;font-size: 12px;margin-top: 5px;    display: block;"> 发表后，客户可以在我的对外资料中查看朋友圈 </span>
        <van-button disabled color="#294ba3" block style="margin-top: 30px;">发表</van-button>
      </div>
    </div>
  </div> -->
  <div class="index" v-if="pageShow">
    <div class="content">
      <div class="title">消息1:</div>
      <van-field
        v-model="text.content"
        rows="2"
        :autosize="{maxHeight:300,minHeight:150}"
        type="textarea"
        maxlength="2000"
        placeholder="请输入内容..."
        show-word-limit
        style="background: #fff ;border: none;padding-bottom: 45px;"
      >
      </van-field>
      <div class="df">
        <div class="title">消息2:</div>
        <van-switch v-model="checked" size="22px"/>
      </div>
      <div v-show="checked">
        <div>
          <div style="margin-top: 10px;">
            <van-radio-group direction="horizontal" v-model="attachments.type" @change="TypeChange" :disabled="isShow">
              <van-radio :name="'image'">图片</van-radio>
              <van-radio :name="'video'">视频</van-radio>
              <van-radio :name="'link'">图文链接</van-radio>
            </van-radio-group>
          </div>
          <div class="mt10">
            <template v-if="attachments.type == 'image'">
              <uploads :num="1" :file-type="1" :list="showList" @success="changeImg"></uploads>
              <div class="tips">tips:图片不超过1440*1080</div>
            </template>
            <template v-if="attachments.type == 'video'">
              <div class="show-add">
                <vUpload
                  class="audio-upload"
                  @success="uploadSuccessV"
                  :file-type="3"></vUpload>
                <div class="show-add-aggregate audio-button">
                  <div v-if="!this.upLoadRes.videoPath" class="show-add-button">
                    <img class="video_img" src="../../assets/file-add.png"/>
                    上传视频
                  </div>
                  <div v-else class="show-add-button">
                    <img class="video_img" src="../../assets/link-url.png"/>
                    <span class="overfls">{{ this.upLoadRes.videoName }}</span>
                  </div>
                  <div>(视频上传大小不超过10M,视频时长不超过30s)</div>
                </div>
              </div>
            </template>
            <template v-if="attachments.type == 'link'">
              <div class="linkType">
                <div>
                  <div>图文封面：</div>
                  <div><uploads :list="showList" :file-type="1" @success="changeImg"></uploads></div>
                </div>
                <div >
                  <div>链接地址：</div>
                  <div><a-input v-model="link.link_url" placeholder="链接地址必须以http://或https://开头" /></div>
                </div>
                <div >
                  <div>图文标题：</div>
                  <div><a-input v-model="link.link_title" placeholder="请输入图文标题" /></div>
                </div>
                <div >
                  <div>图文描述：</div>
                  <div><a-input v-model="link.link_desc" placeholder="请输入图文描述" /></div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <!-- <div class="btn" @click="publish">
        发表
      </div> -->
      <van-button class="btn" type="info" :disabled="disabled" @click="publish">发表</van-button>
    </div>
  </div>
</template>

<script>
import { workMomentInfo, storeMoment, finishMoment } from "../../api/workMoment"
import uploads from './components/upload.vue'
import vUpload from './components/vUpload.vue'
import { Toast } from 'vant'
import { shareToExternalMoments } from "../../utils/wxCodeAuth"
export default {
  components: { uploads, vUpload },
  data() {
    return {
      // // 文字
      // message: '',
      // // tbas切换
      // tabsChose: 0,
      pageShow: false,
      moment_id: '',
      isShow: false, // 是从info页面过来的
      text: { // 消息1
        content: ''
      },
      checked: false, // 是否开启消息2,
      attachments: {
        type: ''
      },
      uploadImg: [], // 消息内容2的图片,
      showList: [],
      upLoadRes: { // 视频
        videoPath: '',
        videoName: ''
      },
      link: { // 图文
        link_url: '',
        link_title: '',
        link_desc: ''
      },
      baseUrl: '',
      disabled: false
    }
  },
  watch: {},
  created () {
    this.baseUrl = process.env.VUE_APP_API_BASE_URL + '/static/'
    this.moment_id = this.$route.query.momentId // 从info过来，有id表示是编辑
    if (this.moment_id) {
      this.getInfo()
    } else {
      this.pageShow = true
    }
  },
  methods: {
    getInfo () {
      workMomentInfo({ moment_id: this.moment_id }).then((res) => {
        if (res.code == 200 && res.data) {
          this.text = res.data.show.text
          // 处理消息2反显数据
          this.showData(JSON.parse(JSON.stringify(res.data)))
          this.pageShow = true
        }
      })
    },
    showData (data) {
      if (data.show.type !== '') { // 如果有就显示消息2
        this.checked = true //消息2开关打开
        this.isShow = true // 反显回来的禁用单选
      }
      if (data.show.type === 'image') { // 反显iamge
        this.attachments.type = 'image'
        this.showList = data.show.image // 反显用的数据
        this.showList.forEach((item) => { // 处理成upload需要的格式
          item.uid = Math.random().toString(36).substring(7),
          item.name = Math.random().toString(36).substring(7),
          item.status = 'done',
          item.url = this.baseUrl + item.image
        })
        this.uploadImg = data.show.image
      }
      if (data.show.type === 'video') {
        this.attachments.type = 'video'
        this.upLoadRes.videoPath = data.show.video.video
        this.upLoadRes.videoName = '视频'
      }
      if (data.show.type === 'link') {
        this.attachments.type = 'link'
        this.link = data.show.link// 反显用的数据
        this.uploadImg.push({pic: data.show.link.link_pic})
        let list = [{ // 处理成upload需要的格式
          uid : Math.random().toString(36).substring(7),
          name : Math.random().toString(36).substring(7),
          status : 'done',
          url : this.baseUrl + data.show.link.link_pic
        }]
        this.showList = list
      }
    },
    onClickLeft() {
      this.$router.back()
    },
    TypeChange (e) {
      console.log(e)
      this.uploadImg = []
      this.upLoadRes = {}
      this.link = {}
    },
    changeImg (e) {
      console.log(e, 'eeee-------img')
      this.uploadImg = []
      this.uploadImg = e
    },
    uploadSuccessV (data) {
      console.log(data)
      this.upLoadRes = {}
      const videoPath = data.path
      this.upLoadRes.videoPath = videoPath
      this.upLoadRes.videoName = data.name
    },
    publish () {
      if (this.text.content == '') {
        Toast('请填写发表内容')
        return
      } 
      const pubData = { // 提交数据
        id: this.moment_id,
        text: this.text,
        attachments: []
      }
      if (this.checked) { // 开启了消息2
        if (this.attachments.type == 'image') {
          if (this.uploadImg.length == 0) {
            Toast('请上传图片')
            return
          }
          pubData.attachments = this.uploadImg.map((i) => {
            return {
              type: this.attachments.type,
              image: this.moment_id ? i.image : i.response.data.path
            }
          })
        } else if (this.attachments.type == 'video') {
           if (this.upLoadRes.length == 0) {
            Toast('请上传视频')
            return
          }
          pubData.attachments.push({
            type: this.attachments.type,
            video: this.upLoadRes.videoPath
          })
        } else if (this.attachments.type == 'link') {
          if (this.uploadImg.length == 0 || this.link.link_url == '' || this.link.link_title == '' || this.link.link_desc == '' || this.link.link_url == undefined || this.link.link_title == undefined || this.link.link_desc == undefined) {
            Toast('请确认图文内容')
            return
          }
          pubData.attachments.push({
            type: this.attachments.type,
            link_pic: this.moment_id ? this.link.link_pic : this.uploadImg[0].response.data.path,
            ...this.link
          })
        }
      }
      this.disabled = true
      this.publishMoment(pubData)
      console.log(pubData, 'pubData1111111')
    },
    publishMoment (data) {
      storeMoment(data).then(res => {
        // console.log(res)
        if (res.code == 200) {
          this.disabled = false
          shareToExternalMoments(true, res.data.send, '').then(res=>{
            //跳转企微发布朋友圈页面了
            // this.finish()
          })
        } else {
          Toast(res.message)
        }
      }).finally(() => {
        setTimeout(() => {
          this.loading = false
        }, 100)
      })
    },
    finish () {
      finishMoment({moment_id: this.moment_id}).then(res => {
        if (res.code == 200) {
          this.$message.success('发布成功')
          this.$router.push('./index')
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-tabs__line {
  bottom: 9px;
  margin-top: 0;
}

/deep/ .van-tabs__nav--line {
  padding-bottom: 1px;
}

/deep/ .van-tab__text {
  margin-bottom: 10px;
}

/deep/ .van-tab {
  background: #ffffff;
}

/deep/ .van-tabs__nav {
  background: #F1f2f5;
}

/deep/ .van-empty__image img {
  object-fit: contain;
}

/deep/ .van-field__button {
  position: absolute;
  bottom: -36px;
  font-size: 12px;
  border: 1px solid #294ba3;
  padding: 0;
  color: #294ba3;
  background: #fff;
  padding: 1px 12px;
  border-radius: 18px;
  right: -14px;
}
/deep/ .van-uploader__input-wrapper{
  width: 100%;
}
/deep/ .van-switch__node {
  top: -0.5px;
}

.van-popover__content {
  .options {
    margin-top: 0;
    margin-bottom: 0;
    list-style: none;
    opacity: 1;
    z-index: 10;
    background: #fff;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 25%);
    border-radius: 2px;
    padding: 6px 0;

    .clickable {
      padding: 16px 24px 16px 21px;
      word-break: keep-all;
      white-space: nowrap;
      font-size: 14px;
      line-height: 1;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
      align-items: center;

      img {
        width: 14px;
        height: 14px;
        margin-right: 8px;
        margin-bottom: 0;
      }
    }
  }
}

.index {
  font-size: 14px;
  height: 100%;
  // background:  #f2f4f8;
  padding: 10px;

  .content {
    // background:#f2f4f8;
    // height: 92%;
    //padding-top: 5px;
    img {
      width: 22px;
      height: 22px;
    }
    .title {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 16px;
    }
    .df {
      display: flex;
      margin-top: 10px;
      .title {
        margin-right: 10px;
      }
    }
    .input_row{
      margin-top: 20px;
      display: flex;
      
    }
    .mt10 {
      margin-top: 10px;
      .tips {
        font-size: 10px;
        color: #eac67d;
      }
    }
    .show-add{
      background: #fbfbfb;
      // width: 86%;
      // margin-left: 6%;
      border: 1px solid #eee;
      display: flex;
      flex-direction: column;
      align-items: baseline;
      padding: 12px 16px;
      min-height: 152px;
      .audio-upload{
        position: absolute;
        z-index: 99;
        /* top: 50%; */
        /* transform: translate(-50%); */
        // left: 28px;
        // top: 28px;
        opacity: 0;
      }
      .show-add-aggregate{
        display: flex;
        align-items: center;
        width: 100%;
        div:nth-child(2){
          color: rgba(0, 0, 0, 0.45);
          max-width: 267px;
          text-align: left;
        }
      }
      .show-add-tips{
        display: flex;
        align-items: baseline;
        flex-direction: column;
        display: flex;
        -webkit-box-align: baseline;
        align-items: baseline;
        padding: 12px 19px;
        margin-bottom: 12px;
        background: #f7fcff;
        border-radius: 3px;
        border: 1px solid #acd7ff;
        font-size: 12px;
        width: 100%;
        span:nth-child(2){
          margin-left: 40px;
        }
      }
      .applet-display{
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        font-size: 10px;
        font-weight: 400;
        color: rgba(0,0,0,.45);
        line-height: 14px;
        width: 100%;
        margin-left: 13px;
        margin-top: 7px;
        img{

          width: 18px !important;
          background: #696ee2;
          border-radius: 50%;
          padding: 4px !important;
          margin: 0 !important;
          margin-right: 4px!important;
        }
      }
      .applet-title{
        padding: 2px 0;
        font-size: 13px;
        font-weight: 400;
        color: rgba(0,0,0,.85);
        line-height: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-all;
        width: 100%;
        padding-left: 21px;
      }
      .applet-image{
        width: 100% !important;
        margin: 0 10px;
      }
      .applet-bottom{
        justify-self: end;
        display: flex;
        align-items: center;
        justify-content: start;
        width: 100%;
        padding-left: 8px;
        border-top: 1px solid #eee;
        padding-top: 4px;
        font-size: 10px;
        font-weight: 400;
        color: rgba(0,0,0,.45);
        border-top: 1px solid #f2f2f2;
        img{
          width: 8.5px !important;
          margin: 0 !important;
          margin-right: 3px!important;}
      }
      .show-add-button{
        width: 170px;
        min-height: 126px;
        -webkit-box-pack: center;
        justify-content: center;
        background: #fbfbfb;
        border-radius: 2px;
        border: 1px dashed #eee;
        position: relative;
        margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        &:hover{
          border: 1px dashed #1890ff!important;
        }
        img{
          width: 28px;
          margin-bottom: 9px;
        }

      }
    }
    .video_img {
      width: 28px;
      margin: 9px 10px;
    }
    .link {
      width: 100%;
    }
    .btn {
      width: 150px;
      height: 39px;
      margin: 20px auto;
      background: #1677FF;
      border-radius: 20px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 39px;
      text-align: center;
    }

    // .title {
    //   padding: 12px 16px;
    //   justify-content: space-between;
    //   font-weight: 400;
    //   line-height: 18px;
    //   font-size: 12px;
    //   color: #545454;

    //   .circle-z {
    //     font-weight: 500;
    //     margin-right: 5px;
    //     color: #000;
    //   }
    // }

    // .content-body {
    //   border-top: 8px solid #f4f5f7;
    // }

    // .item-list {
    //   .item {
    //     padding: 12px 16px;
    //     /* border: 1px solid red; */
    //     border-bottom: 1px solid #f4f5f7;

    //     div:nth-child(1) {
    //       display: flex;

    //       img {
    //         width: 55px;
    //         height: 55px;
    //       }

    //       span {
    //         color: #444;
    //         font-size: 15px;
    //         margin-left: 10px;
    //       }
    //     }

    //     div:nth-child(2) {
    //       color: #939393;
    //       margin-top: 10px;
    //       font-size: 12px;
    //     }
    //   }
    // }

  }
}
</style>
