<template>
  <div class="index">
    <div class="content">
      <van-search v-model="serchValue" @clear="serchData" @search="serchData" placeholder="请输入搜索关键词" />
      <van-divider style="margin: 0 16px" />
      <p class="title">
        <span class="circle-z">{{page.total}}条</span>
        <!-- <span>历史朋友圈已发表</span> -->
        <span>历史朋友圈</span>
      </p>
      <p class="btn">
        <van-button icon="plus" type="info" @click="toCreate">发表朋友圈</van-button>
      </p>
      <div class="content-body">
        <div v-if="listData.length > 0">
          <div v-for="(i,is) in listData" class="item-list" :key="is">
            <div class="item" >
              <div>
                <div style="width: 55px;height: 55px;flex-wrap: wrap;overflow: hidden;" v-if="i.contentType == '图片'">
                  <img v-for="(it,ins) in i.image" :key="ins" :src="it.image" />
                </div>
                <div style="width: 55px;height: 55px;" v-if="i.contentType == '视频'">
                  <video :src="i.video"></video>
                </div>
                <span>{{ i.text.content }}</span>
              </div>
              <div>{{ i.createTime }} 由 我 发布</div>
            </div>
            <div class="wait" v-if="i.status === 0" @click="toPublish(i)">待发布</div>
            <div class="finish" v-if="i.status === 1">已发布</div>
          </div>
        </div>

        <van-empty
            v-else
            class="custom-image"
            :image="require('../../assets/images/emptyimg.png')"
            description="当前没有发表的内容"
        />
      </div>
    </div>
    <!--  新建  -->
    <van-action-sheet
        v-model="shows"
        cancel-text="取消"
        description="你要创建哪种活码？"
        close-on-click-action
    >
      <template slot="default">
        <div @click="onSelect(item.name)" v-for="(item,index) in actions" :key="index"
             class="checkMarkting van-hairline--bottom">
          <div class="info"><p class="fs17 title">{{ item.name }}</p>
            <span class="infotxt fs13">{{ item.subname }}</span>
          </div>
          <div class="more">
            <img src="../../assets/images/marktingAddeb.svg" class="fs16 svg-icon"
                 style="width: 16px; height: 16px;">
          </div>
        </div>
      </template>
    </van-action-sheet>
  </div>
</template>

<script>
import {workMoment} from "../../api/workMoment"
export default {
  data() {
    return {
      // 文字
      message: '',
      // tbas切换
      tabsChose: 0,
      serchValue: '',
      // 新建操作
      shows: false,
      actions: [
        {name: '员工活码', subname: '客户扫码添加员工时，自动给客户打标签、发欢迎语'},
        {name: '客户群活码', subname: '客户扫码加入群聊，群人数达到上限后切换新群聊'}
      ],
      page:'',
      listData:""
    }
  },
  watch: {},
  methods: {
    onClickLeft() {
      this.$router.back()
    },
    onSearch(e){
    },
    // 新建操作
    onSelect(e) {
      this.shows = false
    },
    show() {
      this.shows = true
    },
    list(){
      workMoment({
        searchText: this.serchValue,
        page:1,
        perPage: 1000}).then(res=>{
        this.page = res.data.page
        this.listData = res.data.list
      })
    },
    serchData(){
      this.list()
    },
    toPublish (i) {
      this.$router.push({
        path: '/workMoment/info',
        query: {
          momentId: i.id
        }
      })
    },
    toCreate () {
      this.$router.push('./create')
    }
  },
  created() {
    this.list()
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-tabs__line {
  bottom: 9px;
  margin-top: 0;
}

/deep/ .van-tabs__nav--line {
  padding-bottom: 1px;
}

/deep/ .van-tab__text {
  margin-bottom: 10px;
}

/deep/ .van-tab {
  background: #ffffff;
}

/deep/ .van-tabs__nav {
  background: #F1f2f5;
}

/deep/ .van-empty__image img {
  object-fit: contain;
}
/deep/ .van-action-sheet__description {
  font-size: 16px;
  color: #444;
}


.checkMarkting {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 18px 10px 18px;
  .fs17 {
    font-size: 15px!important;
  }

  .fs13 {
    font-size: 12px!important;
  }

  .info {
    .title {
      color: #000;
      margin-bottom: 5px;
    }

    .infotxt {
      color: #8c8c8c;
      line-height: 21px;
      flex-grow: 1;
    }

    .more {
      width: 16px;
      height: 16px;
      margin-right: 20px;

      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.index {
  font-size: 14px;
  height: 100%;
  background: #fff;

  p {
    margin: 0 !important;
  }

  .fs17 {
    font-size: 17px;
  }

  .fs14 {
    font-size: .14px;
  }
  .content {
    background: #fff;
    height: 92%;
    //padding-top: 5px;
    img {
      width: 22px;
      height: 22px;
    }
    .title{
      padding: 12px 16px;
      justify-content: space-between;
      font-weight: 400;
      line-height: 18px;
      font-size: 12px;
      color: #545454;
      .circle-z{
        font-weight: 500;
        margin-right: 5px;
        color: #000;
      }
    }
    .btn {
      width: 100%;
      padding: 0 12px;
      /deep/.van-button {
        width: 100%;
      }
    }
    .content-body{
      border-top: 8px solid #f4f5f7;
    }
    .item-list{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 16px;
      .wait {
        // width: 60px;
        padding: 3px 8px;
        font-size: 12px;
        border: 1px solid #f6dfb6;
        background-color: #fdf5e3;
        text-align: center;
        color: #eac67d;
        border-radius: 5px;
      }
      .finish {
        // width: 60px;
        padding: 3px 8px;
        font-size: 12px;
        border: 1px solid #dff1d7;
        background-color: #f0f8ec;
        text-align: center;
        color: #61b839;
        border-radius: 5px;
      }
      .item{
        padding: 12px 16px;
        /* border: 1px solid red; */
        border-bottom: 1px solid #f4f5f7;

        div:nth-child(1){
          display: flex;
          img{
            flex: 1;
            /* height: 30px; */
            display: block;
            object-fit: cover;
            height: 100%;
          }
          span{
            color: #444;
            font-size: 15px;
            margin-left: 10px;
          }
        }
        div:nth-child(2){
          color: #939393;
          margin-top: 10px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
