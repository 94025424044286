import request from "../plugins/axios";

export function workMoment (params) {
	return request({
		url: '/workMoment/index',
		method: 'GET',
		params
	})
}

// 详情
export function workMomentInfo (params) {
	return request({
		url: '/workMoment/info',
		method: 'GET',
		params
	})
}
// 创建/修改
export function storeMoment (params) {
	return request({
		url: '/workMoment/store',
		method: 'post',
		data: params
	})
}
// 完成
export function finishMoment (params) {
	return request({
		url: '/workMoment/finish',
		method: 'post',
		data: params
	})
}
