<template>
  <div>
    <a-upload
      name="file"
      list-type="picture-card"
      :headers="headers"
      method="post"
      :action="uploadApi"
      :before-upload="beforeUpload"
      @preview="handlePreview"
      @change="handleChange"
      :accept="'image/*'"
      :file-list="fileList">
      <div v-if="fileList.length < num">
        <a-icon type="plus" />
        <div class="ant-upload-text">
          上传图片
        </div>
      </div>
    </a-upload>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
<script>
import { getCookie } from '@/plugins/utils'
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
export default {
  model: {
    prop: 'modelVal',
    event: 'changeImg'
  },
  props: {
    fileType: {
      type: Number || Array,
      default: 1
    },
    modelVal: {
      default: '',
      type: String
    },
    num: {
      type: Number,
      default: 1
    },
    list: {
      type: Array,
      default: []
    }
  },
  data () {
    return {
      loading: false,
      imageUrl: '',
      FileTypeArr: [
        '',
        ['jpg', 'png', 'jpeg'],
        ['mp3', 'amr'],
        ['mp4'],
        ['doc', 'docx', 'xls', 'xlsx', 'csv', 'ppt', 'pptx', 'txt', 'pdf', 'Xmind']
      ],
      uploadApi: process.env.VUE_APP_API_BASE_URL + '/workbench/common/upload',
      fileList: [],
      previewVisible: false,
      previewImage: ''
    }
  },
  computed: {
    headers () {
      const token = getCookie('workbench_token')
      return {
        Accept: `application/json`,
        Authorization: 'Bearer ' + token
      }
    }
  },
  created () {
  },
  mounted () {
    if (this.list.length > 0) {
      this.fileList = this.list
    }
  },
  methods: {
    showImg (img) {
      this.imageUrl = img
    },
    handleChange (info) {
      console.log(info, 'qqqq')
      this.fileList = info.fileList
      if (info.file.status === 'done') {
        this.$emit('success', this.fileList)
      }
      if (info.file.status === 'removed') {
        this.$emit('success', this.fileList)
      }
      // if (info.file.status === 'uploading') {
      //   this.loading = true
      //   return
      // }
      // if (info.file.status === 'done') {
      //   const data = info.file.response.data
      //   this.$emit('success', data)
      //   this.$emit('changeImg', data.path)
      //   // this.imageUrl = data.fullPath
      //   this.fileList = info.fileList
      //   this.loading = false
      // }
      // if (info.file.status === 'error') {
      //   if (info.file.response) {
      //     const data = info.file.response
      //     this.$message.error(data.msg)
      //   }
      // }
    },
    beforeUpload (file) {
      return new Promise((resolve, reject) => {
        const fileSuffix = this._getFileSuffix(file.name).toLowerCase()
        const fileType = file.type.split('/')[0]
        const file2M = file.size / 1024 / 1024 < 2
        const file10M = file.size / 1024 / 1024 < 10
        let ary = []
        if (this.fileType instanceof Array) {
          this.fileType.map(item => {
            ary.concat(this.FileTypeArr[item])
          })
        } else {
          ary = this.FileTypeArr[this.fileType]
        }
        const flag = ary.includes(fileSuffix)
        if (!flag) {
          this.$message.error(`您只能上传以下类型： ${ary.join(',')}`)
          // return false
          return reject(new Error(false))
        }
        if (fileType === 'video') {
          if (!file10M) {
            this.$message.error('上传文件过大')
            return reject(new Error(false))
          }
          // return flag && file10M
          return resolve(true)
        }

        if (!file2M) {
          this.$message.error('上传文件过大')
          return reject(new Error(false))
        }
        // return flag && file2M
        return resolve(true)
      })
    },
    // 获取文件后缀
    _getFileSuffix (imgName) {
      return imgName.substring(imgName.lastIndexOf('.') + 1)
    },
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleCancel () {
      this.previewVisible = false
    }
  }
}
</script>
<style lang="less">
.avatar-uploader > .ant-upload {
  width: 100px;
  height: 100px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.img-wrapper {
  .img {
    width: 100px;
    height: 100px;
  }
}
.btn-type {
  width: 0;
  height: 0;
  .ant-upload {
    width: 0;
    height: 0;
    border: 0;
    background: #fff;
  }
}
</style>
